import { AfterModifier, Modifier } from 'react-day-picker'

/**
 * Takes a react-day-picker Modifier or Modifier[] type, and "localises" Dates to the users timezone
 * i.e. '2022-01-25T12:00:00.000Z' -> 'Tue Jan 25 2022 00:00:00' (in local timezone)
 *
 * This function is only required due to a problem in react-day-picker's handling of datetimes
 * (when passed a UTC date-time, react-day-picker will still "localise" it to the browser's timezone,
 * potentially altering it to a different date)
 */
export const localiseDayPickerModifier = (days: Modifier | Modifier[]) => {
  if (!dayPickerModifierHasValue(days)) return []

  if (Array.isArray(days)) {
    return days.map(day => removeTimeFromModifier(day))
  }

  return [removeTimeFromModifier(days)]
}

/**
 * Checks a react-day-picker Modifier type has value
 */
export const dayPickerModifierHasValue = (days?: Modifier | Modifier[]) => {
  if (!days) return false
  if (Array.isArray(days)) return days.length > 0
  return !!days
}

/**
 * Localises a UTC Date object, discarding the time
 */
const removeTimeFromDate = (date: Date) =>
  new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())

/**
 * Identifies modifier before removing the time from the Date
 */
const removeTimeFromModifier = (day: Modifier) => {
  const afterModifier = (day as AfterModifier)?.after

  if (day instanceof Date) {
    return removeTimeFromDate(day)
  }
  if (afterModifier) {
    return { after: removeTimeFromDate(afterModifier) }
  }

  throw new Error(
    'This modifier has not been covered by this function yet. Please, add it to the rest of the logic.'
  )
}
