import React from 'react'
import dayjs, { Dayjs } from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import utc from 'dayjs/plugin/utc'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import {
  DayPickerInputProps,
  DayPickerProps,
  Modifiers
} from 'react-day-picker'
import styled, { CSSObject } from '@emotion/styled'
import ValidationError from 'library/molecules/ValidationError'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import determineColorForHover from 'helpers/determineColorForHover'
import { DefaultTheme } from 'assets/theme/theme'
import arrow from 'assets/icons/individualIconsForSprite/general/arrow-left.svg'
import 'react-day-picker/lib/style.css'
import { rgba } from 'polished'
import {
  localiseDayPickerModifier,
  dayPickerModifierHasValue
} from 'shared/helpers/localiseDayPickerModifier'
import { useField } from 'shared/providers/FormProvider/formProvider'

export type OnDateChange = (day?: Dayjs) => void

export type OnMonthChange = (date: Dayjs) => void

export interface CalendarProps
  extends Pick<DayPickerInputProps, 'placeholder' | 'format'> {
  availableDays?: DayPickerProps['selectedDays']
  initialMonth?: DayPickerProps['initialMonth']
  partiallyAvailableDays?: DayPickerProps['disabledDays']
  name: string
  className?: string

  /**
   * There are two layouts available for the calendar.
   * The attribute showInput handles it:
   * false - Show only the calendar.
   * true - Show an input for text that loads the calendar on click.
   */
  showInput?: boolean
  /**
   * The Calendar uses `name` to know which Formik value should be update when
   * a day has been selected but it only covers the option to interact with one field.
   * Use the following attribute to run extra actions when a day has been selected.
   *
   * Useful for closing the calendar modal when an available date is selected.
   */
  onDateChange?: OnDateChange
  /**
   * A handler for when the user alters the currently viewed month, passing in a dayjs object
   * of the first day of the displayed month.
   *
   * Useful for requesting new calendar data from the backend on a month by month basis
   */
  onMonthChange?: OnMonthChange
  /**
   * The date the calendar considers as "today", informing which dates are in the past and
   * should be marked as disabled. Used primarily for testing
   */
  start?: Date
}

const headerHeight = 60

/**
 * This is a date picker to provide a simple way to select a value
 * from the calendar form. This has Formik baked into it for ease of use.
 */
const Calendar = ({
  placeholder,
  format = 'DD/MM/YYYY',
  name,
  availableDays,
  partiallyAvailableDays,
  className,
  showInput = false,
  onDateChange,
  initialMonth,
  onMonthChange,
  start
}: CalendarProps) => {
  const { locale } = useRouter()
  const { t } = useTranslation('common')
  const {
    field,
    meta,
    helpers: { setValue }
  } = useField<Dayjs | undefined | string>(name)
  const { value } = field
  /**
   * When calendar is used in combination with input type='date' the value is converted to string.
   */
  const valueIsString = typeof value === 'string'

  // Listing the months and weekdays in the current locale
  dayjs.extend(localeData)
  // Ensuring dates in dayjs are handled as UTC
  dayjs.extend(utc)

  const parseDate: DayPickerInputProps['parseDate'] = (str, format) => {
    const parsed = dayjs(str, format)
    parsed.isValid() ? parsed.toDate() : undefined
  }

  const formatDate: DayPickerInputProps['formatDate'] = (date, format) => {
    return dayjs(date).format(format)
  }

  const handleDayChange: DayPickerInputProps['onDayChange'] = day => {
    const FORMAT_DATE = 'YYYY-MM-DD'
    const prevValue = valueIsString ? dayjs(value) : value

    /**
     * If the user clicks the same date again then clear the selected date.
     * This was chosen for pages where we use the calendar to filter dates as it
     * is the most space solution in terms of screen real estate
     */
    if (
      prevValue &&
      prevValue.format(FORMAT_DATE) === dayjs(day).format(FORMAT_DATE)
    ) {
      // When the value is a string Formik expects it to be an empty string if is undefined
      setValue(valueIsString ? '' : undefined)
      if (onDateChange) onDateChange(undefined)
      return
    }
    /**
     * Setting hours to 0 - this means that on selection we get a time that is before any possible timeslots that can
     * be on on that day. (by default react-day-picker uses 12:00 as the time on any selection for some reason)
     * Setting hours to 0 is desirable in our main use case of the Calendar component - for when we are selecting
     * dates to find timeslots on. If we kept this at midday then it would filter out the morning timeslots.
     */
    const date = dayjs.utc(day).hour(0)

    setValue(valueIsString ? date.format(FORMAT_DATE) : date)
    if (onDateChange) onDateChange(date)
  }

  /**
   * Casts the newly selected month (represented as the first day) to a dayjs object and passes it to the handler
   */
  const handleMonthChange = (date: Date) =>
    onMonthChange && onMonthChange(dayjs(date))

  /**
   * Disable all dates prior to today (either from today prop or dayjs)
   */
  const disabledDays = {
    before: (start ? dayjs(start) : dayjs()).toDate()
  }

  /**
   * Matching days with modifiers
   * With modifiers you change the aspect of the day cells and customize the interaction with the calendar.
   * When a modifier matches a specific day, its day cells receives the modifier’s name as CSS class.
   *
   * @docs http://react-day-picker.js.org/docs/matching-days/
   */

  const modifiers: Partial<Modifiers> = {
    available: localiseDayPickerModifier(availableDays),
    partiallyAvailable: localiseDayPickerModifier(partiallyAvailableDays),
    past: disabledDays
  }

  const selectedDays = value ? dayjs(value, format).toDate() : undefined

  return (
    <StyledCalendarContainer showInput={showInput} className={className}>
      <DayPickerInput
        showOverlay={!showInput} // only show calendar on render if input is hidden
        hideOnDayClick={showInput} // hide on click when the input is visible
        inputProps={{ ...field }}
        dayPickerProps={{
          modifiers,
          disabledDays,
          initialMonth: initialMonth ?? start ?? dayjs().toDate(),
          selectedDays,
          // localize the calendar
          locale,
          months: dayjs.months(),
          weekdaysLong: dayjs.weekdays(),
          weekdaysShort: dayjs.weekdaysShort(),
          firstDayOfWeek: dayjs.localeData().firstDayOfWeek(),
          onMonthChange: handleMonthChange
        }}
        formatDate={formatDate}
        format={format}
        parseDate={parseDate}
        placeholder={placeholder ? placeholder : t('search-by-date')}
        onDayChange={handleDayChange}
        value={selectedDays}
      />
      {/* Display errors if validation fails */}
      {meta.touched && meta.error ? (
        <ValidationError>{meta.error}</ValidationError>
      ) : null}
      {dayPickerModifierHasValue(partiallyAvailableDays) && (
        <StyledCalendarIsPartiallyAvailableWarningText>
          {t('calendar-partially-available')}
        </StyledCalendarIsPartiallyAvailableWarningText>
      )}
    </StyledCalendarContainer>
  )
}

export default Calendar

export const CALENDAR_SPACING_STYLES = (theme: DefaultTheme): CSSObject => ({
  marginTop: theme.space[5],
  marginBottom: theme.space[5],
  paddingRight: theme.space[5],
  paddingLeft: theme.space[5],
  [theme.mediaQueries.lg]: {
    marginBottom: theme.space[0],
    paddingRight: theme.space[9],
    paddingLeft: theme.space[9],
    paddingBottom: theme.space[5]
  }
})

// Generate a shared style with color variations
const generateDayStyle = ({
  theme,
  state
}: {
  theme: DefaultTheme
  state: 'available' | 'partiallyAvailable'
}): CSSObject => {
  const ignoredClasses =
    ':not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--past)'
  return {
    backgroundColor: theme.colors.calendar[state].background,
    color: theme.colors.calendar[state].text,
    '&:hover': {
      backgroundColor:
        determineColorForHover(theme.colors.calendar[state].background) +
        '!important' // using important to overwrite the library's style without targeting loads of classes.
    },
    '&.DayPicker-Day--selected': {
      color: theme.colors.calendar[state].background,
      [`&${ignoredClasses}`]: {
        color: theme.colors.calendar[state].background
      },
      [`&${ignoredClasses}:hover`]: {
        color: determineColorForHover(theme.colors.calendar[state].background)
      }
    }
  }
}

const StyledCalendarContainer = styled.div<
  Required<Pick<CalendarProps, 'showInput'>>
>(
  ({ theme }) => ({
    /* WRAPPER */
    '.DayPicker': {
      display: 'flex',
      flexDirection: 'row',
      margin: 0,
      padding: 0,
      width: '100%',
      height: '100%',
      fontFamily: theme.fonts.heading,
      fontSize: theme.fontSizes.md,
      fontWeight: theme.fontWeights.heading
    },
    '.DayPicker-wrapper': {
      position: 'relative',
      flexDirection: 'row',
      userSelect: 'none',
      width: '100%',
      margin: '0',
      padding: '0'
    },
    /* NAVBAR */
    '.DayPicker-NavBar': {
      position: 'absolute',
      top: 0,
      right: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: headerHeight, // using fixed height to match with DayPicker-Caption
      padding: `0 ${theme.space[2]}px`
    },
    '.DayPicker-NavButton': {
      position: 'relative',
      overflow: 'hidden',
      top: 'inherit',
      right: 'inherit',
      left: 'inherit',
      bottom: 'inherit',
      height: 40,
      width: 40,
      margin: 0,
      background: 'transparent',
      borderRadius: '50%',
      /**
       * Importing a single SVG file instead of the sprite file because the sprite didn't
       * work on `background-image`. This exception is acceptable for this library
       * because we need to overwrite their style.
       *
       * TODO Look into importing sprite as background in case this happens more often.
       */
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'block',
        height: '100%',
        width: '100%',
        background: theme.colors.icon.grey,
        transition: `transform ${theme.transitionTime}s ease`
      },
      '&--prev::before': {
        marginRight: 0,
        mask: `url(${arrow}) center / 20px no-repeat`
      },
      '&--next::before': {
        mask: `url(${arrow}) center / 20px no-repeat`,
        transform: 'rotate(180deg)'
      },
      '&:hover': {
        background: rgba(theme.colors.primary, 0.1),
        '&::before': {
          background: theme.colors.primary
        }
      }
    },
    /* CAPTION */
    '.DayPicker-Caption': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: headerHeight, // using fixed height to match with DayPicker-NavBar
      margin: 0,
      padding: 0,
      width: '100%',
      fontFamily: theme.fonts.heading,
      fontSize: theme.fontSizes.md,
      border: theme.border,
      borderLeft: 0,
      borderRight: 0,
      '& > div': {
        fontWeight: theme.fontWeights.heading
      }
    },
    /* Months */
    '.DayPicker-Months': {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      margin: 0,
      padding: 0
    },
    /* MONTH */
    '.DayPicker-Month': {
      display: 'flex',
      flexWrap: 'wrap',
      margin: '0',
      padding: '0',
      marginTop: 'auto',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      userSelect: 'none',
      boxSizing: 'content-box',
      '& > *': {
        width: '100%'
      }
    },
    /* WEEK */
    '.DayPicker-Week': {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.space[1],
      '&:first-of-type': {
        marginTop: 0
      }
    },
    /* WEEKS */
    '.DayPicker-Weekdays': {
      display: 'flex',
      marginTop: 0,
      marginBottom: theme.space[2],
      fontFamily: theme.fonts.heading,
      fontSize: theme.fontSizes.xs,
      borderLeft: 0,
      borderRight: 0,
      borderBottom: theme.border
    },
    '.DayPicker-WeekdaysRow': {
      display: 'flex',
      justifyContent: 'space-around',
      width: '100%',
      paddingTop: theme.space[4],
      paddingBottom: theme.space[4]
    },
    '.DayPicker-Weekday': {
      display: 'flex',
      justifyContent: 'center',
      fontFamily: theme.fonts.body,
      fontWeight: theme.fontWeights.body,
      fontSize: theme.fontSizes.xs,
      color: theme.colors.text.secondary,
      padding: 0
    },
    /* DAY */
    '.DayPicker-Day': {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginLeft: theme.space[1],
      padding: 0,
      fontSize: theme.fontSizes.lg,
      color: theme.colors.calendar.unavailable.text,
      background: theme.colors.calendar.unavailable.background,
      border: '4px solid transparent', // add transparent border to facilitate animation on day modifiers
      borderRadius: theme.radii[1],
      boxSizing: 'border-box',
      outline: 'none',
      transition: `all ${theme.transitionTime[1]}s ease`,
      '&:first-of-type': {
        marginLeft: 0
      },
      // this pseudo element helps to create a responsive square
      '&::before': {
        content: '""',
        display: 'block',
        height: 0,
        width: 0,
        paddingBottom: '100%'
      },
      /* DAY - MODIFIERS */
      '&--today': {
        fontWeight: 'inherit'
      },
      '&--available': {
        ...generateDayStyle({ theme, state: 'available' })
      },
      '&--partiallyAvailable': {
        ...generateDayStyle({ theme, state: 'partiallyAvailable' })
      },
      '&--selected': {
        backgroundColor: 'transparent',
        borderColor: 'currentcolor',
        '&:focus': {
          outline: 'none'
        },
        '&:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
          backgroundColor: 'inherit'
        },
        '&:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover': {
          backgroundColor: 'transparent!important'
        }
      },
      '&--past, &--outside': {
        pointerEvents: 'none',
        cursor: 'auto',
        color: theme.colors.calendar.past.text,
        background: theme.colors.calendar.past.background
      },
      /**
       * Style for disabled days.
       * Any day that is not marked as ".DayPicker-Day--available" will be identified as disabled.
       *
       * DayPickerInput accepts the prop disabledDays that would need us to write a function to find which days are
       * disabled by using the value from availableDays. We are not using this approach because it adds a delay
       * while selecting a day. The following code did the same thing by just using CSS but kept the flow fast.
       */
      '&:not(.DayPicker-Day--available)': {
        pointerEvents: 'none',
        cursor: 'auto'
      }
    },
    /* Overlay */
    '.DayPickerInput-Overlay': {
      position: 'relative',
      left: 'inherit',
      boxShadow: 'none'
    }
  }),

  /**
   * There are two ways of using the calendar.
   * showInput:
   * false - Show the whole calendar.
   * true - Show an input for text that loads the calendar on click.
   */
  ({ theme, showInput }) =>
    // only load pseudo element if "check" is true
    showInput
      ? {
          /* DayPickerInput */
          '.DayPickerInput': {
            position: 'relative',
            width: '100%',
            '& > input': {
              position: 'relative',
              textAlign: 'center',
              fontSize: theme.fontSizes.md,
              background: 'none',
              border: '1px solid ' + theme.colors.border.primary,
              borderRadius: theme.radii[1],
              height: 36,
              zIndex: 3
            }
          }
        }
      : {
          '.DayPickerInput': {
            width: '100%',
            '& > input': {
              display: 'none'
            }
          }
        }
)

const StyledCalendarIsPartiallyAvailableWarningText = styled.div(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.space[2],
    padding: theme.space[5],
    color: theme.colors.text.secondary,
    borderTop: theme.border,
    '&::before': {
      content: '""',
      display: 'block',
      marginRight: theme.space[5],
      width: 24,
      minWidth: 24,
      height: 24,
      alignSelf: 'flex-start',
      color: theme.colors.calendar.partiallyAvailable.background,
      background: theme.colors.calendar.partiallyAvailable.background,
      borderRadius: theme.radii[2]
    }
  })
)
