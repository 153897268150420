import { VOUCHERS_TITLE } from 'enums/vouchers'
import widgetRoutes from 'enums/routes'
import { DEFAULT_FALLBACK_IMAGE } from 'enums/image'
import { TopLevelCard } from 'helpers/determineTopLevelChargeableData'
import { MEMBERSHIPS_TITLE } from 'enums/memberships'
import { RENTALS_TITLE } from 'shared/enums/rentals'
import { ACTIVITIES_TITLE } from 'enums/activities'
import { Schedulables } from 'shared/presenters/graphqlTypes'
import { RecursivePartial } from 'shared/presenters/presenter'

export type ChargeableTitle =
  | typeof ACTIVITIES_TITLE
  | typeof VOUCHERS_TITLE
  | typeof RENTALS_TITLE
  | typeof MEMBERSHIPS_TITLE

export const DEFAULT_TITLES: ChargeableTitle[] = [
  ACTIVITIES_TITLE,
  VOUCHERS_TITLE,
  RENTALS_TITLE,
  MEMBERSHIPS_TITLE
]

type KeyImage =
  | 'activity_key_image'
  | 'voucher_key_image'
  | 'rental_key_image'
  | 'membership_key_image'

export const DEFAULT_TITLE_TO_IMAGE_KEY: Record<ChargeableTitle, KeyImage> = {
  [ACTIVITIES_TITLE]: 'activity_key_image',
  [VOUCHERS_TITLE]: 'voucher_key_image',
  [RENTALS_TITLE]: 'rental_key_image',
  [MEMBERSHIPS_TITLE]: 'membership_key_image'
}

export const DEFAULT_IMAGE = DEFAULT_FALLBACK_IMAGE

export const ACTIVITIES_CARD = ({
  widgetSlug,
  cardImage,
  schedulable
}: {
  widgetSlug: string
  cardImage: string
  schedulable: RecursivePartial<Schedulables>
}): TopLevelCard => ({
  title: ACTIVITIES_TITLE,
  href: widgetRoutes.ACTIVITIES.url({ widgetSlug }),
  cardImage: cardImage,
  schedulable
})

export const VOUCHER_CARD = ({
  widgetSlug,
  cardImage
}: {
  widgetSlug: string
  cardImage: string
}): TopLevelCard => ({
  title: VOUCHERS_TITLE,
  href: widgetRoutes.VOUCHERS.url({ widgetSlug }),
  cardImage: cardImage,
  schedulable: {}
})

export const RENTALS_CARD = ({
  widgetSlug,
  cardImage
}: {
  widgetSlug: string
  cardImage: string
}): TopLevelCard => ({
  title: RENTALS_TITLE,
  href: widgetRoutes.RENTALS.url({ widgetSlug }),
  cardImage,
  schedulable: {}
})

export const MEMBERSHIPS_CARD = ({
  widgetSlug,
  cardImage
}: {
  widgetSlug: string
  cardImage: string
}): TopLevelCard => ({
  title: MEMBERSHIPS_TITLE,
  href: widgetRoutes.MEMBERSHIPS.url({ widgetSlug }),
  cardImage,
  schedulable: {}
})
