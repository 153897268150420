import React from 'react'
import styled from '@emotion/styled'
import { space, SpaceProps } from 'styled-system'

interface TagsListProps extends SpaceProps {
  tags?: string[]
}

interface TagProps {
  // Add extra styling if the tag has an icon.
  variant?: 'icon'
}

/**
 * It uses the styled-system to handle all the props related to space.
 * Pass the rest of the props "...other" to the element that should be styled with styled-system.
 * */
export const TagsList = ({ tags, ...other }: TagsListProps) => {
  if (!tags) return null

  return (
    <StyledTagsList {...other}>
      {tags
        .map((label, index) => {
          // Add a divider for any item after the first one
          if (index > 0) {
            return [
              <StyledDivider key={label + '--divider'} />,
              <Tag key={label}>{label}</Tag>
            ]
          }
          return <Tag key={label}>{label}</Tag>
        })
        .flat()}
    </StyledTagsList>
  )
}

const Tag = styled.li<TagProps>(
  ({ theme }) => ({
    background: theme.colors.background.secondary,
    padding: theme.mixins.pxSpread([theme.space[2], theme.space[3]]),
    borderRadius: theme.radii[3],
    color: theme.colors.text.secondary,
    ...theme.fontStyle.p3,
    ...theme.mixins.capitalizeFirstLetter()
  }),
  ({ variant }) =>
    variant === 'icon' && {
      display: 'flex',
      listStyleType: 'none',
      alignItems: 'center',
      textTransform: 'none',
      zIndex: 0
    }
)

export default Tag

const StyledTagsList = styled.ul(
  ({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItem: 'center',
    padding: 0,
    listStyleType: 'none',
    margin: 0,
    gap: theme.space[3]
  }),
  space
)

const StyledDivider = styled.hr(({ theme }) => ({
  background: theme.colors.icon.grey,
  height: 4,
  width: 2,
  margin: `auto ${theme.space[3]}px`,
  border: 'none',
  borderRadius: theme.radii[3]
}))
